import { Experiment } from '@types'
import survey from '../survey'

const r2: Experiment = {
  ...survey,
  id: 'r2',
  funnel: 'INSTA1',
  default: false,
}

export default r2
