import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { clearQuizProgress, selectCurrentStep } from 'redux/quiz/steps'
import Header from '@components/Header'
import Footer from '@components/Footer'
import { SaveUpToBanner } from '@components/Banner/SaveUpToBanner'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const [layoutMinHeight, setLayoutMinHeight] = useState('100vh')
  const dispatch = useAppDispatch()
  const currentStep = useSelector(selectCurrentStep)
  const router = useRouter()
  const { asPath, query, pathname } = router

  const queryClass = Object.keys(query).map(k => query[k] && `${k}-${query[k]}`)

  const pathClass = pathname.split('/')

  // unique array of class names that combines
  // the path and query class names as well as currentStep
  const classNames = [
    ...new Set([
      'layout',
      currentStep ? `step-${currentStep}` : pathname === '/' ? 'step-1' : '',
      ...pathClass,
      ...queryClass,
    ]),
  ]
    .join(' ')
    .trim()

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      // if the path is the initial home page, clear the quiz progress
      if (asPath === '/') {
        dispatch(clearQuizProgress())
      }
    })
  }, [asPath, dispatch, router.events])

  useEffect(() => {
    // update min height to be the height of the window/viewport only for mobile screen
    if (window.innerWidth < 768) {
      setLayoutMinHeight(`${window.innerHeight}px`)
    }
  }, [layoutMinHeight])

  // if path starts with /graph, then skip layout and just show the graph
  if (pathname.startsWith('/graph')) {
    return <>{children}</>
  }

  return (
    <>
      <div className={classNames} style={{ minHeight: layoutMinHeight }}>
        <SaveUpToBanner />
        <Header />
        <div className="items-center flex flex-col flex-1 px-5">
          <div className="layout-inner-1">
            <div className="layout-inner-2">
              <div className="w-full flex flex-col items-center justify-start md:pt-8">
                {children}
              </div>
            </div>
          </div>
          <Footer path={asPath} />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Layout
