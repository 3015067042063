import Actions from '@components/quiz/actions'
import { StepComponentProps, TextQuizStep } from '@types'
import {
  getMaxZipCode,
  getMinZipCode,
  getStateByZipCode,
} from '@utils/zip-code'
import { isNumber } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'

type Props = StepComponentProps<TextQuizStep, string>

const maxZipCode = getMaxZipCode()
const minZipCode = getMinZipCode()

const ZipCodeComponent: React.FC<Props> = ({
  isLastStep,
  nextStep,
  onChange,
  previousStep,
  step,
  value,
}: Props) => {
  const [result, setResult] = useState<string | null>()
  const zipCodeRef = useRef<HTMLInputElement>(null)
  const stateRelated = useRef<string | undefined | null>(null)

  useEffect(() => {
    const valueNumber = value ? parseInt(value) : false
    if (isNumber(valueNumber)) {
      if (valueNumber >= minZipCode && valueNumber <= maxZipCode) {
        stateRelated.current = getStateByZipCode(valueNumber)
        setResult(value)
      }
    }
  }, [value])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == '') {
      setResult(null)
    }
    const targetValue = parseInt(event.target.value)

    if (targetValue <= maxZipCode) {
      stateRelated.current = getStateByZipCode(parseInt(event.target.value))
      setResult(event.target.value)
    }
  }

  const nextHandler = () => {
    result && onChange(step.id, result)
  }

  const nextEnabled = useMemo(
    () =>
      !!result &&
      !!stateRelated.current &&
      parseInt(result) >= minZipCode &&
      parseInt(result) <= maxZipCode,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result, stateRelated.current],
  )

  return (
    <div className="max-w-2xl">
      <div className="quiz-text-input-container border-b-2 border-secondary2/25 border-dashed border-t-0 border-x-0 mx-auto flex">
        <input
          onChange={handleInputChange}
          ref={zipCodeRef}
          required
          type="number"
          value={result || ''}
          className="text-center quiz-input no-border"
          placeholder="Zip Code"
          inputMode="numeric"
          pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
          min={minZipCode}
          max={maxZipCode}
        />
        <div className={`state-match ${!!stateRelated.current && 'active'}`}>
          {stateRelated.current}
        </div>
      </div>
      <Actions
        onNext={nextHandler}
        nextEnabled={nextEnabled}
        isLastStep={isLastStep}
        previousStep={previousStep}
        nextStep={nextStep}
      />
    </div>
  )
}

export default ZipCodeComponent
