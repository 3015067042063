import StepLabel from '@components/quiz/step-label'
import StepHint from '@components/quiz/step-hint'
import React, { useEffect, useMemo, useState } from 'react'
import {
  ExperimentPage,
  Gender,
  QuizProps,
  QuizStepType,
  StepChangeHandler,
  StepId,
  URLSearchParams,
} from '@types'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectStepValue, setStepValue } from 'redux/quiz'
import { selectName, setStartTime, setUrlParam } from 'redux/user'
import { components, getQuestionDefaultValue } from './questions'
import { useRouter } from 'next/router'
import { useSearchParams } from 'next/navigation'
import { setQuizProgress } from 'redux/quiz/steps'
import { trackMixpanelEvent } from '@components/Scripts/MixpanelTracker'
import { getStateByZipCode } from '@utils/zip-code'
import { useExperiment } from 'redux/experiment'
import { WhyDoWeAsk } from '@components/WhyDoWeAsk'
import { getCookie } from '@utils/tracking'

const Quiz = (props: QuizProps) => {
  const { step } = props
  const currentPage = props.currentFlowPage || ExperimentPage.QUESTIONS
  const stepValue = useAppSelector(state => selectStepValue(state, step.id))
  const name = useAppSelector(selectName)
  const dispatch = useAppDispatch()
  const { asPath } = useRouter()

  const defaultValue = useMemo(() => getQuestionDefaultValue(step), [step])
  const [value, setValue] = useState(stepValue || defaultValue)
  const StepComponent = useMemo(() => components[step.type], [step.type])
  const { experiment, goNextStepFlow } = useExperiment()
  const searchParams = useSearchParams()
  const permittedInsurance = ['commercial', 'kaiser']

  const replaceName = (value: string) =>
    name ? value.replace(/\[name\]/g, name) : value

  useEffect(() => {
    trackMixpanelEvent(
      `Viewed Quiz ${
        currentPage === ExperimentPage.QUESTIONS_EXTENDED ? 'Extended ' : ''
      }Step ${props.currentStep}`,
      experiment?.funnel,
      {
        id: props?.step?.id,
        label: props?.step?.label,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentStep, props?.step?.label, props?.step?.id])

  useEffect(() => {
    setValue(stepValue || defaultValue)
    dispatch(setQuizProgress(props))
  }, [stepValue, defaultValue, setValue, dispatch, props])

  useEffect(() => {
    // path excluding query params
    const path = asPath.split('?')[0]

    if (props.currentStep > 2) {
      return
    }

    if (
      path === '/' ||
      path === '/quiz' ||
      path === '/quiz/start-female' ||
      path === '/quiz/start-male'
    ) {
      dispatch(setStartTime())

      const urlParams: URLSearchParams = {
        fbclid: searchParams.get('fbclid') || '',
        _fbc: getCookie('_fbc') || '',
        _fbp: getCookie('_fbp') || '',
        utm_source: searchParams.get('utm_source') || '',
        utm_medium: searchParams.get('utm_medium') || '',
        utm_campaign: searchParams.get('utm_campaign') || '',
        utm_term: searchParams.get('utm_term') || '',
        utm_content: searchParams.get('utm_content') || '',
      }

      dispatch(setUrlParam(urlParams))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])

  const changeHandler: StepChangeHandler<unknown> = (
    name: string,
    value: unknown,
  ) => {
    setValue(value)

    // TODO: Refactor, there's probably a much cleaner way to do this
    if (
      step.type === QuizStepType.SINGLE_CHOICE ||
      step.type === QuizStepType.LAB_RESULTS
    ) {
      dispatch(setStepValue({ stepId: step.id, value }))
      goNextStepFlow({
        currentPage,
        isLastStep: props.isLastStep,
        nextStep: props.nextStep,
      })
    }
    if (step.type === QuizStepType.ZIPCODE) {
      dispatch(setStepValue({ stepId: step.id, value }))
      dispatch(
        setStepValue({
          stepId: StepId.STATE,
          value: getStateByZipCode(value as number),
        }),
      )
      goNextStepFlow({
        currentPage,
        isLastStep: props.isLastStep,
        nextStep: props.nextStep,
      })
    }
    if (step.type === QuizStepType.INSURANCE) {
      dispatch(setStepValue({ stepId: step.id, value }))
      goNextStepFlow({
        currentPage,
        isLastStep: !permittedInsurance.includes(value as string),
        nextStep: props.nextStep,
        lastPageUrl: '/ineligible-insurance',
      })
    }
    if (step.id === StepId.GENDER && (value as Gender) === 'male') {
      dispatch(setStepValue({ stepId: StepId.PREGNANT, value: 'no' }))
    }
  }

  const submitHandler = (e: React.SyntheticEvent<Element, Event> | null) => {
    if (e) e.preventDefault()

    dispatch(setStepValue({ stepId: step.id, value }))

    trackMixpanelEvent(
      `Answered Quiz Step ${props.currentStep}`,
      experiment?.funnel,
      {
        id: props?.step?.id,
        label: props?.step?.label,
        value,
      },
    )

    goNextStepFlow({
      currentPage,
      isLastStep: props.isLastStep,
      nextStep: props.nextStep,
      lastPageUrl: props.lastPageUrl,
    })
  }

  return (
    <div className={['quiz-step fade-in', step.id, step.type].join(' ')}>
      {(step.label || step.hint) && (
        <div className={`quiz-header ${step.hint ? 'with-hint' : ''}`}>
          {step.pretext && (
            <h3 className="mb-10 mt-4 quiz-paragraph max-w-md mx-auto text-center">
              {replaceName(step.pretext as string)}
            </h3>
          )}
          {step.label && <StepLabel label={replaceName(step.label)} />}
          {step.hint && <StepHint hint={replaceName(step.hint)} />}
        </div>
      )}
      <form className="quiz-content" onSubmit={submitHandler}>
        <StepComponent
          {...props}
          key={step.id}
          value={value}
          step={step}
          onChange={changeHandler}
        />
        {step.whyDoWeAsk && (
          <WhyDoWeAsk
            label={step.whyDoWeAsk.label}
            description={step.whyDoWeAsk.description}
          />
        )}
      </form>
    </div>
  )
}

export default Quiz
