import { Plan, Track } from '@types'

const production = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

export const rxOffers = [
  'Virtual Visit w/ Licensed Physician',
  'Unlimited messaging w/ Physician & Care Team',
  'Prescription for Weight Loss Medication',
  '1:1 Coaching w/ a Registered Dietitian',
  'Personalized Meal Plan w/ Grocery Lists',
]

export const nutritionOffers = [
  '1:1 Coaching w/ a Registered Dietitian',
  'Personalized Meal Plan w/ Grocery Lists',
  'Access to Private Community',
]

export const medicalPlans: Plan[] = [
  {
    id: 'Measured Rx - Semaglutide',
    sku: 'Ongoing care',
    duration: 1,
    amount: 29700,
    trialPeriodDays: 0,
    oneTimeFee: 24700,
    name: 'Measured Rx - Semaglutide',
    line_item: 'Measured Rx - Semaglutide',
    billed: 'monthly',
    billed_short: 'mo',
    stripe_product_id: production
      ? 'prod_PXqRmGHeR9ISFl'
      : 'prod_PQhnLdhNsFLVEn',
    stripe_price_id: production
      ? 'price_1OikkmBS2APHTgAqD8S8rsdN'
      : 'price_1ObqNfBS2APHTgAqVySdBPYE',
    deal: null,
    billed_long: 'Billed every month',
    originalPrice: 297,
    salePrice: 247,
    productName: 'Measured Rx',
    productNameLong: 'Measured Rx - Semaglutide',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    showBonus: false,
    bonus: null,
    offers: rxOffers,
    track: Track.MEDICAL,
  },
]

export const initialDrConsutation: Plan = {
  id: 'PT-INIT-CONSULT',
  sku: 'PT-INIT-CONSULT',
  duration: 1,
  amount: 3900,
  name: 'Virtual Visit',
  line_item: 'Virtual Visit',
  billed: 'onetime',
  stripe_product_id: production ? 'prod_M7qsvnHYAVlcSk' : 'prod_LxIjQTNqtOxExC',
  stripe_price_id: production
    ? 'price_1MbWuQBS2APHTgAqedNF5Gd2'
    : 'price_1MbWuuBS2APHTgAq6O8hDdtn',
  deal: null,
  billed_long: 'One time fee',
  originalPrice: 78,
  salePrice: 39,
  productName: 'Virtual Visit',
  productNameLong: 'Virtual Visit',
  showMarkdownPrice: true,
  showSavedPercent: true,
  showSpecialOffer: 'Best Deal',
  showBonus: false,
  bonus: null,
  offers: rxOffers,
  track: Track.MEDICAL,
  description:
    'Virtual visit with obesity-trained clinician. Includes all lab costs.',
}

const plans = { medicalPlans, initialDrConsutation }

export const defaultPlan = () =>
  medicalPlans.find(plan => plan.sku === 'Ongoing care') || medicalPlans[0]

export default plans
