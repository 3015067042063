import Modal from '@components/Modal'
import ContinueButton from '@components/ContinueButton'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { setCookie } from 'cookies-next'
import { selectPromoCode } from 'redux/order'
import { useSelector } from 'react-redux'
import { XMarkIcon } from '@heroicons/react/20/solid'
import fireConfetti from '@utils/confetti'

// const SHOW_IN_PATH = ['/your-plan', '/checkout']
const HIDE_IN_PATH = ['/confirmation', '/checkout', '/thanks']
const COOKIE_KEY_SAVE_UP_TO = 'keyQuizSaveUpToBanner'

export const SaveUpToBanner = () => {
  const [showModal, setShowModal] = useState(false)
  const [isClosed, setClosed] = useState<string | boolean>(false)
  const [discountApplied, setDiscountApplied] = useState(false)
  const [shouldRender, setShouldRender] = useState(false)
  const promoCode = useSelector(selectPromoCode)
  const { pathname } = useRouter()

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const closeTopBanner = () => {
    setCookie(
      COOKIE_KEY_SAVE_UP_TO,
      true,
      { maxAge: 60 * 60 }, // 1 hour
    )
    setClosed(true)
  }

  useEffect(() => {
    setShouldRender(
      !!promoCode && !isClosed && !HIDE_IN_PATH.includes(pathname),
    )
  }, [promoCode, pathname, isClosed])

  const updateTextAndClose = () => {
    setDiscountApplied(true)
    fireConfetti({ count: 200 })

    setTimeout(() => {
      toggleModal()
    }, 2000)
  }

  const defaultCopy = (
    <>
      <div className="font-serif text-2xl font-medium mb-4">Special Offer</div>
      <p className="mb-2 text-xl">Join today and get</p>
      <div className="mb-2 text-5xl italic flex items-center justify-center">
        <div className="text-gray-300 h-5 px-2">~</div>
        <div>$50 OFF</div>
        <div className="text-gray-300 h-5 pr-2">~</div>
      </div>
      <p className="text-lg">Your first month by using</p>
      <p className="mb-9 text-lg ">
        coupon <strong className="text-red-700">{promoCode?.code}</strong> at
        checkout.
      </p>
      <div className="max-w-xs mx-auto">
        <ContinueButton
          enabled={true}
          text="Apply Discount"
          size="large"
          extraClasses="shadow-pulse dark-button"
          onClick={updateTextAndClose}
        />
      </div>
    </>
  )

  const updatedCopy = (
    <>
      <p className="mb-3 text-2xl font-semibold italic font-serif">
        Discount Applied!
      </p>
      <p className="mb-9 text-lg px-8">
        Please continue to checkout to complete your order.
      </p>
      <div className="max-w-xs mx-auto">
        <ContinueButton
          enabled={true}
          text="Continue"
          size="large"
          extraClasses="shadow-pulse dark-button"
          onClick={toggleModal}
        />
      </div>
    </>
  )

  return !shouldRender ? null : (
    <>
      <Modal
        showModal={showModal}
        toggleModal={toggleModal}
        modalClass="modal-save-up-to"
        closeIconColor="text-white"
      >
        <div className="mx-auto py-2 text-xl flex flex-col items-center justify-center">
          {discountApplied ? updatedCopy : defaultCopy}
        </div>
      </Modal>
      <div
        className="banner cursor-pointer"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <div className="flex items-center gap-x-6 bg-gray-900 px-6 py-2.5 md:py-2 sm:px-3.5 sm:before:flex-1">
          <p className="text-sm leading-6 text-white">
            <span>
              <span className="font-medium">Special offer</span> - $50 off your
              first month!
            </span>
          </p>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              onClick={closeTopBanner}
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
