import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Gender,
  Macros,
  Track,
  Units,
  Nullable,
  CareTeamMember,
  URLSearchParams,
} from '@types'
import { convertWeightFromMetricToImperial } from '@utils/convert-units'
import { selectAnswers } from '../quiz'
import { RootState } from '../store'

// Define a type for the slice state
type UserState = {
  name: Nullable<string>
  email: Nullable<string>
  phone: Nullable<string>
  macros: Nullable<Macros>
  leadId: Nullable<string>
  track: Nullable<Track> | null
  track_reasons: Nullable<string[]>
  readonly uid: string
  info: any
  startTime: Nullable<number>
  id: Nullable<string>
  schedulingLink: Nullable<string>
  magicLink: Nullable<string>
  careTeamMembers: Array<CareTeamMember>
  urlParams: URLSearchParams
}

// Define the initial state using that type
const initialState: UserState = {
  id: null,
  name: null,
  email: null,
  macros: null,
  leadId: null,
  phone: null,
  uid: Math.random().toString(26).slice(2),
  info: {},
  track: null,
  track_reasons: [],
  startTime: null,
  schedulingLink: null,
  magicLink: null,
  careTeamMembers: [],
  urlParams: {
    fbclid: '',
    _fbc: '',
    _fbp: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload.toLowerCase()
    },
    setMacros: (state, action: PayloadAction<Macros>) => {
      state.macros = action.payload
    },
    setLeadId: (state, action: PayloadAction<string>) => {
      state.leadId = action.payload
    },
    setTrack: (state, action: PayloadAction<any>) => {
      state.track = action.payload
    },
    setTrackReasons: (state, action: PayloadAction<string[]>) => {
      state.track_reasons = action.payload
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.info = action.payload
    },
    setStartTime: state => {
      state.startTime = +new Date()
    },
    setUserSchedulingLink: (state, action: PayloadAction<string>) => {
      state.schedulingLink = action.payload
    },
    setMagicLink: (state, action: PayloadAction<string>) => {
      state.magicLink = action.payload
    },
    setCareTeamMembers: (state, action: PayloadAction<CareTeamMember[]>) => {
      state.careTeamMembers = action.payload
    },
    // allow us set a specific URL param and have it update just that param
    setUrlParam: (state, action: PayloadAction<URLSearchParams>) => {
      // merge the new params with the existing ones
      state.urlParams = { ...state.urlParams, ...action.payload }
    },
    reset: () => initialState,
  },
})
export const selectUser = (state: RootState): UserState => state.user
export const selectName = (state: RootState): Nullable<string> =>
  selectUser(state).name
export const selectEmail = (state: RootState): Nullable<string> =>
  selectUser(state).email
export const selectUserId = (state: RootState): Nullable<string> =>
  selectUser(state).id
export const selectPhone = (state: RootState): Nullable<string> =>
  selectUser(state).phone
export const selectMacros = (state: RootState): Macros =>
  selectUser(state).macros as Macros
export const selectLeadId = (state: RootState): Nullable<string> =>
  selectUser(state).leadId
export const selectStartTime = (state: RootState): Nullable<number> =>
  selectUser(state).startTime
export const selectSchedulingLink = (state: RootState): Nullable<string> =>
  selectUser(state).schedulingLink
export const selectMagicLink = (state: RootState): Nullable<string> =>
  selectUser(state).magicLink
export const selectCareTeamMembers = (state: RootState): CareTeamMember[] =>
  selectUser(state).careTeamMembers

export const selectUrlParams = (state: RootState): URLSearchParams =>
  selectUser(state).urlParams

export const selectCurrentWeight = (
  state: RootState,
  unit: Units,
): Nullable<number> => {
  const currentWeight = selectAnswers(state).current_weight
  if (unit === 'imperial') {
    return convertWeightFromMetricToImperial(currentWeight)
  }
  return currentWeight
}

export const selectTargetWeight = (
  state: RootState,
  unit: Units,
): Nullable<number> => {
  const weight = selectAnswers(state).goal_weight
  if (unit === 'imperial') {
    return convertWeightFromMetricToImperial(weight)
  }
  return weight
}

export const selectProgressAmount = (state: RootState) =>
  selectAnswers(state).progressAmount

export const selectGender = (state: RootState): Gender =>
  selectAnswers(state).gender

export const selectUid = (state: RootState): string => selectUser(state).uid

export const {
  setUserId,
  setName,
  setEmail,
  setMacros,
  setLeadId,
  setUserInfo,
  setPhone,
  setTrack,
  setTrackReasons,
  setStartTime,
  setUserSchedulingLink,
  setMagicLink,
  setCareTeamMembers,
  setUrlParam,
  reset,
} = userSlice.actions

export const selectTrack = (state: RootState): Track => state.user.track

export const selectTrackReasons = (state: RootState): string[] =>
  state.user.track_reasons

export const selectMaxWeightLoss = (state: RootState) => {
  const currentWeight = selectAnswers(state).current_weight

  const halfWeight = convertWeightFromMetricToImperial(currentWeight * 0.5)
  return Math.min(150, halfWeight)
}

export default userSlice.reducer
